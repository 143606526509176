.audio-page-main {
    width: 765px;
    margin: 0 auto;
    font-family: Calibri, sans-serif;
    padding: 45px 0;

    .start-container {
        .error-notification {
            margin-bottom: 15px;
        }
        .container-header {
            text-align: center;
        }
        .select-all-row {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .select-all-button {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                width: fit-content;
                overflow: hidden;
                border-radius: 7px;
                transition: 0.2s;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0px 3px 10px 0px rgba(34, 60, 80, 0.2);
                }
                .select-all-icon {
                    margin-left: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 20px;
                        height: auto;
                        aspect-ratio: 1/1;
                    }
                }
                .select-all-text {
                    margin: 5px 10px;
                }
            }
            .select-checked {
                box-shadow: 0px 3px 10px 0px rgba(34, 60, 80, 0.2);
                &:hover {
                    cursor: pointer;
                    box-shadow: 0px 3px 10px 0px rgba(34, 60, 80, 0.3);
                }
            }
        }
        .audio-list {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 15px;
            .audio-selected {
                background-color: #ffc80082;
            }
            .audio {
                box-shadow: 0px 5px 10px 0px rgba(34, 60, 80, 0.2);
                width: 180px;
                height: 180px;
                border-radius: 7px;
                display: flex;
                flex-direction: column;
                transition: box-shadow 0.2s;
                padding: 10px;
                &:hover {
                    cursor: pointer;
                    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
                }
                .audio-number {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 7px;

                    .type-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            height: 20px;
                            width: auto;
                            aspect-ratio: 1/1;
                        }
                    }
                    .incoming-call {
                        color: #5ed9e8;
                    }
                    .outgoing-call {
                        color: #8cfa8c;
                    }
                    .number-text {
                        font-weight: 400;
                    }
                }
                hr {
                    width: 100%;
                    margin: 5px 0 5px 0;
                    border-color: rgba(87, 87, 87, 0.2);
                    border-width: 0.2px;
                    box-shadow: none;
                }
                .audio-data-row {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    height: fit-content;
                    .row-icon {
                        display: flex;
                        padding-top: 4px;
                        svg {
                            height: 15px;
                            width: auto;
                            aspect-ratio: 1/1;
                        }
                    }
                    .row-text {
                        .audio-row-title {
                            font-weight: 400;
                        }
                        height: fit-content;
                    }
                }
                .audio-date {}
                .audio-duration {}
                .call-provider {}
                .last-call-element {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                }
            }
        }
        .interact-buttons {
            margin-top: 20px;
            display: flex;
            position: sticky;
            width: 765px;
            bottom: 45px;
            flex-direction: row;
            justify-content: space-between;
            background: white;
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            z-index: 15;
            overflow: hidden;
            border-radius: 7px;
            .selected-counter {
                margin: 10px 15px;
            }
            transition: opacity .2s;
            &.hide {
                opacity: 0;
                pointer-events: none;
                transition: opacity .2s;
            }
            button {
                border: none;
                padding: 10px 15px;
                background: #d6c0fa;
                cursor: pointer;
                transition: background-color 0.2s;
                &:hover {
                    background: #ccacfd;
                }
            }
        }
    }
}