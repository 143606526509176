
.preloader{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 1;
  visibility: visible;
  transition: 0.3s;

  &.hide{
    opacity: 0;
    visibility: hidden;
  }

  .loading {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;

    div {
      display: inline-block;
      position: absolute;
      left: 0px;
      width: 10px;
      background: #000000;
      animation: preloader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 0px;
        animation-delay: -0.24s;
      }
      &:nth-child(2) {
        left: 14px;
        animation-delay: -0.12s;
      }
      &:nth-child(3) {
        left: 28px;
        animation-delay: 0;
      }
    }
  }

  @keyframes preloader {
    0% {
      top: 5px;
      height: 30px;
    }
    50%, 100% {
      top: 10px;
      height: 20px;
    }
  }
}

